.Settings {
  width: 100%;
  padding: 30px 200px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.Settings h1 {
  color: #4a90e2;
  font-family: "MainFont";
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 90%;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

.SettingsFun {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0;
}

.SettingsFun label {
  font-family: "MainFont";
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.SettingsFun select,
.SettingsFun textarea {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  font-size: 16px;
  font-family: "MainFont";
  color: #555;
  transition: all 0.3s ease;
}

.SettingsFun select:focus,
.SettingsFun textarea:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.4);
}

.SettingsFun textarea {
  min-height: 80px;
  resize: vertical;
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.save-button,
.delete-button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: "MainFont";
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.save-button {
  background-color: #4a90e2;
  color: #fff;
  border: none;
}

.save-button:hover {
  background-color: #3b78c1;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
}

.delete-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
}

.delete-button:hover {
  background-color: #c0392b;
  box-shadow: 0 4px 10px rgba(231, 76, 60, 0.2);
}
