.SC {
  color: #fff;
  font-family: MainFont;
  font-size: 50px;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
