* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff3d3a;
  color: white;
  border-radius: 50%;
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
}

header {
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px #ffffff44;
  overflow: hidden;
  background: #fff;
}
header img {
  width: auto;
  height: 100%;
}
.navbar {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: fit-content;
  z-index: 1;
  gap: 60px;
}
.navbar button {
  color: #1a58b6;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  min-width: fit-content;
  padding: 5px;
  height: 40px;
  opacity: 10;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: MainFont;
  font-weight: 400;
}
.navbar button:hover {
  cursor: pointer;
  border-bottom: 2px #04a3f3 solid;
}
header .navbtn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}
header .innav {
  visibility: hidden;
  overflow: hidden;
}
@font-face {
  font-family: MainFont;
  src: url("../../Fonts/Rubik-VariableFont_wght.ttf");
}
@media only screen and (max-width: 1350px) {
  header {
    padding-left: 50;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 1150px) {
  header {
    padding-left: 50px;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 1260px) {
  header {
    padding-left: 0px;
    justify-content: space-around;
  }
  header .navbtn {
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header .innav {
    visibility: visible;
    flex-direction: column;
  }
  .navbar img {
    height: 50px;
  }
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    outline: solid 1px #ffffff44;
  }
  .navbar button {
    margin: 1rem;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: #fff;
    transition: 1s;
    transform: translateX(100%);
    overflow-y: scroll;
  }
  header .responsive_nav {
    transform: none;
  }
  nav .innav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  nav .innav img {
    margin-bottom: 50px;
  }

  nav a {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 460px) {
  header {
    padding-left: 0;
    justify-content: space-around;
  }
  header img {
    height: 60%;
  }
}
@media only screen and (max-width: 414px) {
  header {
    padding-left: 0;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 360px) {
  header {
    padding-left: 0;
    justify-content: space-around;
  }
}
