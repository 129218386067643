.TeacherProfile {
  width: 100%;
  height: fit-content;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  direction: rtl;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.TeacherProfile .TeacherPinfo {
  width: fit-content;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.TeacherPinfo h2 {
  color: #007f7f;
  font-size: 36px;
  font-family: MainFont;
  min-width: 300px;
  text-align: start;
  margin: 10px 0;
  font-weight: bold;
}

.TeacherPinfo p {
  font-size: 20px;
  font-family: MainFont;
  min-width: 300px;
  text-align: start;
  color: #555;
  margin: 5px 0;
}

.TeacherPinfo img {
  width: 200px;
  height: 200px;
  padding: 5px;
  border-radius: 20px;
  object-fit: cover;
  align-self: flex-start;
}
.TeacherPdata {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  max-width: 600px;
  font-family: MainFont;
  color: #333;
}

.TeacherPdata p {
  font-size: 18px;
  margin: 8px 0;
  line-height: 1.6;
  color: #555;
  font-family: MainFont;
}
