.Layers {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-right: 150px;
  flex-direction: column;
  overflow: hidden;
  background: rgb(0, 128, 117);
  direction: rtl;
  gap: 20px;
}

.Layers h1 {
  color: #fff;
  font-size: 50px;
  font-family: MainFont;
}

.Layers p {
  color: #fff;
  font-family: MainFont;
  font-size: 30px;
}

.Layers button {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 15px;
  color: rgb(0, 128, 117);
  font-size: 25px;
  font-family: MainFont;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}

.Layers button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.LayersFun {
  width: 100%;
  height: fit-content;
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  gap: 50px;
}

.LayersFun .specializations,
.LayersContent {
  width: 500px;
  height: fit-content;
  border: rgb(0, 128, 117) 2px solid;
  border-radius: 20px;
  padding: 15px;
  direction: rtl;
}
.DTop {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px #c0c0c0 solid;
  padding-bottom: 20px;
}

.DTop h1 {
  color: rgb(0, 128, 117);
  font-family: MainFont;
  font-size: 40px;
  font-weight: 450;
}

.DTop button {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 15px;
  color: #fff;
  font-size: 25px;
  background: rgb(0, 128, 117);
  font-family: MainFont;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}

.DTop button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
.layer-item,
.specialization-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px 0;
  color: rgb(0, 128, 117);
  font-size: 20px;
  font-family: MainFont;
}

.view-button {
  background-color: rgb(0, 128, 117);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: MainFont;
}

.view-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: rgb(0, 128, 117);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: MainFont;
}

.delete-button:hover {
  background-color: #ff4747;
}
