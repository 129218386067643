.Sec-2 {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 20px;
}

.Sec-2 div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Sec-2 :nth-child(2) {
  flex-direction: column;
  align-items: center;
  padding: 60px;
}
.Sec-2 :nth-child(2) p {
  width: 90%;
  text-align: start;
}

.Sec-2 :nth-child(2) h1 {
  color: #1a58b6;
  text-align: right;
  font-family: MainFont;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Sec-2 :nth-child(2) p {
  color: #00b2ff;
  text-align: right;
  font-family: MainFont;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Sec-2 :nth-child(2) button {
  width: 80%;
  height: 35px;
  border-radius: 20px;
  background: #1a58b6;
  border: none;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 1260px) {
  .Sec-2 {
    height: fit-content;
    padding: 20px 0 20px 0;
    flex-direction: column-reverse;
  }
  .Sec-2 :nth-child(2) {
    padding: 20px 0 20px 0;
  }
}
