.CI {
  color: #00be98;
  font-family: MainFont;
  font-size: 30px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
