.students-list {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 20px;
  flex-direction: column;
  padding: 20px 0 20px 0;
}
.students-list h1 {
  font-size: 30px;
  color: rgb(80, 164, 255);
  font-family: MainFont;
  font-weight: 500;
}
.student-card {
  width: 80%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  direction: rtl;
}
.student-card p {
  width: 200px;
  text-align: start;
  font-family: MainFont;
  font-size: 20px;
}

.phone-numbers .phone-number {
  width: 200px;
  text-align: end;
  font-family: MainFont;
}
