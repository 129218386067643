.Loading {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading img {
  width: auto;
  height: 100%;
}
