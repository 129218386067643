.ViewClasses {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  flex-wrap: wrap;
  direction: rtl;
}

.ViewClasses .classes-list {
  width: 60%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.classes-list .class-item {
  width: 100%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.class-item h1 {
  font-family: MainFont;
  color: #0085be;
}

.class-item p {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-direction: row-reverse;
  min-width: 300px;
  font-family: MainFont;
  color: #0085be;
}

.class-item p img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.MSCB {
  width: 150px;
  height: 35px;
  color: #fff;
  font-family: MainFont;
  border-radius: 10px;
  background: linear-gradient(90deg, #00b2ff 0%, #00e6cd 100%);
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}

.MSCB:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
