.CAM {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 20px;
}

.CAM img {
  width: 30%;
}

.CAM .Userinfo {
  width: 50%;
  height: fit-content;
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  gap: 20px;
}

.Userinfo h1 {
  color: #fe4f5a;
  text-align: center;
  font-family: MainFont;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Userinfo p {
  text-align: center;
  font-family: MainFont;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Userinfo .NewInfo {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.NewInfo input {
  width: 400px;
  height: 35px;
  border: none;
  font-family: MainFont;
  font-size: 20px;
  text-align: end;
  padding-right: 5px;
  border-bottom: 3px solid #fe4f5a;
  background: #fff;
  font-weight: 400;
  outline: none;
}

.Userinfo button {
  width: 400px;
  height: 35px;
  border: 15px;
  border-radius: 15px;
  background: #fe4f5a;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 25px;
  font-family: MainFont;
}
.Userinfo .userimage-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fe4f5a;
  transition: border-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userimage-preview {
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.userimage-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
}

.camera-icon {
  font-size: 30px;
  color: #555;
}

.Userinfo .error-message {
  color: #fe4f5a;
  font-size: 16px;
}
