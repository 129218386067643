.excel-drop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.dropzone {
  width: 100%;
  max-width: 600px;
  height: 150px;
  border: 2px dashed #0068be;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.dropzone p {
  text-align: center;
  color: #0068be;
  font-family: MainFont;
}

.excel-data {
  width: 100%;
  max-width: fit-content;
  overflow-x: auto;
}

.excel-data table {
  width: 100%;
  border-collapse: collapse;
  direction: rtl;
}

.excel-data th,
td {
  padding: 20px;
  width: fit-content;
  border: 1px solid #ddd;
  text-align: right;
}

.excel-data th {
  background-color: rgb(255, 81, 81);
}
