.modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  direction: rtl;
}

.modal-content2 {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  position: relative;
  text-align: center;
  height: 80%;
  overflow-y: scroll;
}

.modal-content2 h1 {
  color: #4caf50;
  font-family: MainFont;
  margin: 20px;
}

.close-button2 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.students-list2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.student-card2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.student-card2 p {
  font-family: MainFont;
}

.attendance-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.attendance-button {
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: MainFont;
}

.present-button {
  background-color: rgb(56, 233, 206);
}

.absent-button {
  background-color: red;
}

.absent-button.disabled {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.6;
}

.absent-button.active {
  background-color: green;
}

.attendance-button.active {
  font-weight: bold;
  opacity: 0.8;
}

.student-name {
  font-size: 16px;
  margin-bottom: 5px;
  transition: color 0.3s ease;
}

.present-name {
  color: rgb(17, 228, 196);
}

.absent-name {
  color: red;
}
