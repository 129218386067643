.date-picker-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.date-picker-container input {
  padding: 12px 15px;
  font-size: 16px;
  border: 2px solid #00bebb;
  border-radius: 8px;
  width: 250px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  text-align: center;
}

.date-picker-container input:focus {
  border-color: #0068be;
  box-shadow: 0 0 10px rgba(0, 104, 190, 0.3);
  outline: none;
}

.dropouts-list {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dropout-item {
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dropout-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.dropout-item p {
  margin: 5px 0;
  font-family: MainFont;
  color: #333;
  text-align: right;
  font-size: 16px;
}

@media (max-width: 768px) {
  .dropouts-list {
    width: 90%;
  }

  .date-picker-container input {
    width: 80%;
  }
}
