.exit-pre-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;
  align-items: center;
}

.exit-pre-docs {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exit-pre-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.exit-pre-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.exit-pre-item p {
  font-size: 1rem;
  color: #333;
  margin: 0;
  flex: 1;
}

.signature-preview img {
  height: 60px;
  width: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 3px;
}

.print-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.print-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.exit-pre-error-message {
  color: #d9534f;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 20px;
}

.date-picker-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.date-picker {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.date-picker:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@media (max-width: 768px) {
  .exit-pre-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .signature-preview img {
    height: 50px;
  }

  .print-button {
    align-self: flex-start;
  }
}

@media (max-width: 480px) {
  .exit-pre-container {
    padding: 10px;
  }

  .exit-pre-item {
    padding: 15px;
  }

  .signature-preview img {
    height: 40px;
  }

  .print-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
