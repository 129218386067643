.CheckUser {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 20px;
  flex-direction: column;
}

.CheckUser img {
  width: 400px;
}

.CheckUser h1 {
  color: #f36564;
  font-family: MainFont;
}

.CheckUser button {
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background: #f36564;
  color: #fff;
  font-family: MainFont;
  font-size: 20px;
  cursor: pointer;
}
