.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}
.modal-content h2 {
  color: rgb(0, 68, 128);
  font-size: 25px;
  font-family: MainFont;
}
.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  font-family: MainFont;
  text-align: end;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.modal-button {
  width: 50%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: MainFont;
  cursor: pointer;
}

.modal-button.cancel {
  background-color: #ff4d4f;
  color: white;
}

.modal-button.save {
  background-color: rgb(0, 68, 128);
  color: white;
}

.modal-button:hover {
  opacity: 0.9;
}
