.ManageStudents {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  flex-wrap: wrap;
  direction: rtl;
}
.ManageStudents .MSL {
  width: 60%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.MSL .layer-item {
  width: 100%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.layer-item h3,
p {
  color: #0085be;
  font-family: MainFont;
}

.layer-item .MSLB {
  width: 150px;
  height: 35px;
  color: #fff;
  font-family: MainFont;
  border-radius: 10px;
  background: linear-gradient(90deg, #00b2ff 0%, #00e6cd 100%);
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}

.layer-item .MSLB:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
