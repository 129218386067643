.HeadContent {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 450px;
  background: url("../../Images/Head.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.HeadContent div {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.HeadContent .Head1 h1 {
  color: #fff;
  font-family: MainFont;
  font-size: 25px;
  font-weight: 400;
}

.HeadContent .Head2 .UserProfile .Data1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
  width: 100%;
  flex-direction: row;
}
.Data1 h2 {
  font-size: 35px;
  color: #fff;
  font-weight: 400;
  font-family: MainFont;
}
.Data1 button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #ff7373;
}
.Data1 img {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 360px;
  object-fit: cover;
}

.HeadContent .Head2 .UserProfile .Data2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: fit-content;
  width: 100%;
}
.Data2 h2 {
  font-size: 35px;
  text-align: end;
  color: #fff;
  font-weight: 400;
  font-family: MainFont;
}
.StatusSelect {
  width: 150px;
  padding: 10px;
  font-size: 1rem;
  background: #ffffff;
  border: 2px solid #1371c9;
  font-family: MainFont;
  border-radius: 15px;
  font-size: 20px;
  color: #1371c9;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.StatusSelect:hover {
  border-color: #0e5b9d;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.StatusSelect:focus {
  border-color: #0e5b9d;
  box-shadow: 0px 0px 5px 2px rgba(19, 113, 201, 0.5);
}

.StatusSelect option {
  font-size: 1rem;
  padding: 10px;
  color: #1371c9;
}
