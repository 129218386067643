.messages-settings {
  font-family: MainFont;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #4888ff;
  border-radius: 10px;
}

.messages-settings h1 {
  text-align: center;
  color: #fff;
  font-family: MainFont;
}

.messages-settings label {
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
  font-family: MainFont;
  color: #fff;
}

.messages-settings select,
.messages-settings input {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: MainFont;
}

.messages-settings select {
  background-color: #fff;
  font-family: MainFont;
}

.messages-settings input {
  font-family: MainFont;
  background-color: #f9f9f9;
}

.messages-settings .message-preview {
  background-color: #fff;
  font-family: MainFont;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 20px;
}

.messages-settings .message-preview h2 {
  font-size: 18px;
  color: #444;
  font-family: MainFont;
  margin-bottom: 10px;
}

.messages-settings .message-preview p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  font-family: MainFont;
}
.save-button2 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  color: #4888ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: MainFont;
  font-size: 16px;
  margin-top: 20px;
}

.success-message {
  color: #fff;
  margin-top: 15px;
  font-size: 16px;
}
