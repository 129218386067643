.Classes {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-right: 150px;
  flex-direction: column;
  overflow: hidden;
  background: rgb(0, 68, 128);
  direction: rtl;
  gap: 20px;
}
.Classes h1 {
  color: #fff;
  font-size: 50px;
  font-family: MainFont;
}

.Classes p {
  color: #fff;
  font-family: MainFont;
  font-size: 30px;
}

.Classes button {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 15px;
  color: rgb(0, 68, 128);
  font-size: 25px;
  font-family: MainFont;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}
.Classes button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.ClassesView {
  width: 100%;
  height: fit-content;
  padding: 50px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ClassesCon {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  gap: 20px;
}
.ClassesView .ClassInfo {
  width: 50%;
  height: 50px;
  direction: rtl;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-around;
  align-items: center;
  direction: rtl;
  padding: 10px;
}
.ClassesView h1 {
  color: rgb(0, 68, 128);
  font-family: MainFont;
  font-weight: 400;
  font-size: 30px;
}
.TeacherSelection {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.TeacherSelection label {
  font-family: MainFont;
}
.TeacherSelection .Select {
  width: 250px;
}
