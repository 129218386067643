* {
  font-family: MainFont;
}

.calendar-container {
  padding: 20px;
  cursor: grab;
  direction: rtl;
  user-select: none;
  overflow: scroll;
  scrollbar-width: none;
}

.calendar-container::-webkit-scrollbar {
  display: none;
}

.calendar-container:active {
  cursor: grabbing;
}

.calendar-header {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #195ec5;
}

.calendar-schedule {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  width: fit-content;
}

.calendar-schedule::-webkit-scrollbar {
  display: none;
}

.calendar-day {
  width: fit-content;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
}

.calendar-day-title {
  font-size: 1.5rem;
  color: #2e3b4e;
  margin-bottom: 10px;
  text-align: start;
}

.calendar-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.calendar-table-header {
  border: 1px solid #e0e0e0;
  padding: 12px;
  background: #f7f7f7;
  text-align: center;
  font-weight: bold;
  color: #333;
  min-width: 200px;
}

.calendar-table-cell {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
}

.calendar-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
}

.calendar-select:hover {
  border-color: #2e3b4e;
}

.Controls {
  width: 300px;
  height: 200px;
  background-color: #fff;
  z-index: 1000;
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  direction: rtl;
  padding: 10px;
  flex-direction: column;
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Controls h1 {
  font-family: MainFont;
  color: #195ec5;
  width: 100%;
  text-align: center;
}

.Controls button {
  width: 90%;
  height: 40px;
  background: #195ec5;
  color: #fff;
  font-family: MainFont;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.Controls button:hover {
  background: #156db6;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .calendar-table {
    min-width: 100%;
  }

  .calendar-table-header,
  .calendar-table-cell {
    padding: 10px;
  }
}
