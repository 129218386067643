.sec-1 {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.svg-background {
  min-width: 100%;
  width: auto;
  height: 100%;
  min-height: 750px;
}

.sec-1 .onTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  padding-bottom: 100px;
}

.onTop div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.onTop .Block1 .Content1 {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.Content1 h1 {
  width: 100%;
  color: #fff;
  text-align: right;
  font-family: MainFont;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Content1 p {
  width: 100%;
  color: #fff;
  text-align: right;
  font-family: MainFont;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Content1 button {
  width: 200px;
  height: 35px;
  border-radius: 10px;
  background: #fff;
  color: #0a8ee2;
  text-align: center;
  font-family: MainFont;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.5s;
}

.Content1 button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}
.Block2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Block2 .Content2 {
  width: 350px;
  height: fit-content;
  position: absolute;
  padding-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
}

.Content2 h1 {
  color: #00b2ff;
  text-align: center;
  font-family: MainFont;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Content2 .Authinputs {
  width: 90%;
  height: fit-content;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.Authinputs input {
  width: 100%;
  height: 35px;
  border: none;
  border-bottom: 3px solid #00b2ff;
  background: #fff;
  outline: none;
  text-align: end;
  padding-right: 10px;
  font-size: 20px;
  font-family: MainFont;
}

.AuthFunctions {
  width: 100%;
  height: fit-content;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.AuthFunctions button {
  width: 90%;
  height: 35px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 25px;
  font-family: Mainfont;
  cursor: pointer;
  transition: 0.5s;
}

.AuthFunctions button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

@media only screen and (max-width: 1260px) {
  .sec-1 {
    height: fit-content;
    overflow-x: hidden;
  }
  .sec-1 .onTop {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 100px;
  }
  .onTop .Block1 .Content1 {
    width: 99%;
    height: fit-content;
    padding: 20px;
  }

  .svg-background {
    min-width: 100%;
    width: auto;
    height: 300px;
    min-height: 600px;
  }
  .onTop .Block1 .Content1 h1 {
    font-size: 55px;
  }
  .onTop .Block1 .Content1 p {
    font-size: 25px;
  }
  .Block2 svg {
    visibility: hidden;
  }
}

.app-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.app-buttons img {
  width: 100px;
  background: #fff;
  border-radius: 360px;
}

.app-store-button button,
.google-play-button button {
  background-color: #007aff;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  font-family: MainFont;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
}

.google-play-button button {
  background-color: #34a853;
}

.app-store-button button:hover,
.google-play-button button:hover {
  opacity: 0.9;
}
.error-message {
  color: red;
  font-size: 15px;
}
