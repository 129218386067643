.SM {
  width: 100%;
  height: fit-content;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.SM .SchoolInfo {
  width: 80%;
  height: fit-content;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  direction: rtl;
  gap: 20px;
  flex-wrap: wrap;
}
.SchoolInfo p {
  color: #00b2ff;
  font-family: MainFont;
  font-size: 20px;
  font-weight: 450;
}

.SM .SMFun {
  width: 80%;
  height: fit-content;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  direction: rtl;
}

.SMFun button {
  width: 400px;
  height: 50px;
  color: #fff;
  font-family: MainFont;
  text-align: start;
  padding-right: 20px;
  border-radius: 10px;
  background: #00b2ff;
  border: none;
  font-size: 30px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}

.SMFun button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
