.ManagerFun {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.ManagerFun .ManagerBtn {
  width: 80%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.ManagerBtn button {
  width: 400px;
  height: 50px;
  color: #fff;
  font-family: MainFont;
  border-radius: 10px;
  background: linear-gradient(90deg, #00b2ff 0%, #00e6cd 100%);
  border: none;
  font-size: 30px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}

.ManagerBtn button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.ManagerData {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
}

.ManagerData h1 {
  color: #00be98;
  font-family: MainFont;
}

.DataContainer {
  width: 100%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  direction: rtl;
}

.DataContainer div {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
  direction: rtl;
  padding: 20px;
  width: 350px;
  height: 200px;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
}

.DataContainer div:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.DataContainer div h1 {
  color: #00be98;
  font-family: MainFont;
}
