.ClassDataFun {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  direction: rtl;
}
.ClassDataFun div {
  border-radius: 10px;
  background: #0068be;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
  direction: rtl;
  padding: 20px;
  width: 350px;
  height: 200px;
  transition: background 0.3s ease, transform 0.5s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.ClassDataFun div:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.ClassDataFun div h1 {
  color: #fff;
  font-family: MainFont;
}

.ClassDataFun button {
  width: 100%;
  height: fit-content;
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 25px;
  font-family: MainFont;
  gap: 10px;
}
