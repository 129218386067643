.teacher-roll-form {
  max-width: 500px;
  margin: 50px auto;
  padding: 40px;
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  font-family: "MainFont", sans-serif;
}

.teacher-roll-form h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #0077b6;
  font-size: 2.2rem;
  font-weight: 700;
  font-family: MainFont;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 14px;
  border: 2px solid #d1d9e6;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s;
  font-family: MainFont;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #0077b6;
  box-shadow: 0 0 5px rgba(0, 119, 182, 0.3);
  outline: none;
}

.specialization-input,
.grade-level-input {
  margin-bottom: 15px;
  position: relative;
  font-family: MainFont;
}

.add-specialization,
.remove-specialization,
.submit-button,
.add-grade-level,
.remove-grade-level {
  background-color: #0077b6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  font-family: MainFont;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
}

.add-specialization:hover,
.remove-specialization:hover,
.submit-button:hover,
.add-grade-level:hover,
.remove-grade-level:hover {
  background-color: #005f8c;
  transform: translateY(-2px);
}

.remove-specialization,
.remove-grade-level {
  background-color: #e63946;
  position: absolute;
  left: -60px;
  top: 5px;
}

.remove-specialization:hover,
.remove-grade-level:hover {
  background-color: #c71f30;
}

.add-specialization,
.add-grade-level {
  margin-top: 10px;
  width: 100%;
}

.submit-button {
  margin-top: 30px;
  width: 100%;
}

.submit-button:hover {
  background-color: #005f8c;
}
