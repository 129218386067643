.teachers-container {
  padding: 20px;
  background-color: #fff;
}

.teacher-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.teacher-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  transition: transform 0.3s ease;
}

.teacher-card:hover {
  transform: translateY(-5px);
}

.teacher-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.teacher-info {
  padding: 15px;
}

.teacher-info h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
  font-family: MainFont;
}

.teacher-info p {
  font-size: 1.1rem;
  color: #7f8c8d;
  font-family: MainFont;
  font-weight: bold;
}

.teacher-info p strong {
  color: #34495e;
  font-family: MainFont;
}

/* Status colors */
.status-available {
  color: #27ae60;
}

.status-in-class {
  color: #f39c12;
}

.status-not-available {
  color: #e74c3c;
}

.status-unknown {
  color: #7f8c8d;
}
.teacher-info button {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
  font-family: MainFont;
  border: none;
  border-radius: 10px;
  background: #27ae60;
  color: #fff;
  font-size: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
  .teacher-cards {
    flex-direction: column;
    align-items: center;
  }

  .teacher-card {
    width: 90%;
  }
}
