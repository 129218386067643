.FS {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  flex-wrap: wrap;
  direction: rtl;
}
.FS .StudentsList {
  width: 80%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.StudentsList .student-item {
  width: 100%;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.student-item h3 {
  color: #00bebb;
  font-family: MainFont;
  font-size: 20px;
  font-weight: 400;
  width: 200px;
  letter-spacing: 1px;
}
.student-item p {
  min-width: 100px;
  font-family: MainFont;
  text-align: end;
}

.search-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-container input {
  width: 60%;
  height: 30px;
  border: none;
  text-align: end;
  border-bottom: 2px #00bebb solid;
  padding: 5px;
  font-size: 23px;
  font-family: MainFont;
  outline: none;
}

.student-item button {
  background-color: transparent;
  border: none;
  color: #0068be;
  cursor: pointer;
}
